import React from "react";
import { PropTypes } from "prop-types";
import { Link as RouterLink, Switch, Route } from "react-router-dom";
import { Link, MenuItem } from "@deity/falcon-ui";
import {
  SignOutLogic,
  NotFound,
  CustomerQuery
} from "@deity/falcon-ecommerce-uikit";
import PersonalInformation from "./PersonalInformation";
import ChangePassword from "./ChangePassword";
import AddressBook from "./AddressBook/AddressBook";
import AddAddress from "./AddressBook/AddAddress";
import EditAddress from "./AddressBook/EditAddress";
import Orders from "./Orders/Orders";
import Order from "./Orders/Order";
import Dashboard from "./Dashboard";
// import PaymentMethod from "./PaymentMethod/PaymentMethod";
import { AccountMenu, ClubBenlux } from "benlux-ecommerce-ui";

const MenuLink = ({ to, children, ...rest }) => (
  <MenuItem {...rest}>
    <Link as={RouterLink} to={to} p="xs" flex={1}>
      {children}
    </Link>
  </MenuItem>
);
MenuLink.propTypes = {
  to: PropTypes.string.isRequired
};

const Account = () => (
  <div>
    <SignOutLogic>
      {({ signOut }) => (
        <div>
          <CustomerQuery>
            {({ customer }) => (
              <AccountMenu signOut={() => signOut()} customer={customer}>
                <Switch>
                  <Route exact path="/compte" component={Dashboard} />
                  <Route exact path="/compte/commandes" component={Orders} />
                  <Route exact path="/compte/commandes/:id" component={Order} />
                  {/* <Route exact path="/compte/club-benlux" component={ClubBenlux} /> */}
                  <Route
                    exact
                    path="/compte/informations-personnelles"
                    component={PersonalInformation}
                  />
                  <Route
                    exact
                    path="/compte/nouveau-mot-de-passe"
                    component={ChangePassword}
                  />
                  {/* <Route exact path="/compte/methode-de-paiement" component={PaymentMethod} /> */}
                  <Route
                    exact
                    path="/compte/carnet-adresse"
                    component={AddressBook}
                  />
                  <Route
                    exact
                    path="/compte/carnet-adresse/ajouter"
                    component={AddAddress}
                  />
                  <Route
                    exact
                    path="/compte/carnet-adresse/modifier/:id"
                    component={EditAddress}
                  />
                  <Route component={NotFound} />
                </Switch>
              </AccountMenu>
            )}
          </CustomerQuery>
        </div>
      )}
    </SignOutLogic>
  </div>
);

export default Account;
