import React from 'react';
import {Shipment} from "benlux-ecommerce-ui";

import {OrderQuery} from "../../../../queries/OrderQuery";

const Order = ({ match }) => {
  const id = parseInt(match.params.id, 10);

  return (
    <OrderQuery variables={{ id }}>
      {({ order }) => 
        <>
          <Shipment order={order}/>
        </>
      }
    </OrderQuery>
  );
};

export default Order;
