import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { T, I18n } from '@deity/falcon-i18n';
import {H1, H2, Box, Link, Icon, Button, Divider, FlexLayout, Text} from '@deity/falcon-ui';
import {
    AddressListQuery,
    AddressCardLayout,
    AddressListLayout,
    getAddressType,
    RemoveAddressMutation
} from '@deity/falcon-ecommerce-uikit';
import {AddAddress as AddButton, AddressCard} from "benlux-ecommerce-ui";
import AddAddress from "./AddAddress";

const AddressBook = () => (
  <Box>
    <H1>
      <T id="addressBook.title" />
    </H1>
    <AddressListQuery>
      {({ addresses: { items } }) => {
        const billing = items.find(x => x.defaultBilling);
        const shipping = items.find(x => x.defaultShipping);
        const rest = items.filter(x => !x.defaultBilling && !x.defaultShipping) || [];
        const anyDefaults = billing || shipping;
        const defaultsEqual = (billing && billing.id) === (shipping && shipping.id);
        const anyRest = rest.length > 0;

        return (
          <React.Fragment>
            {anyDefaults && (
              <AddressListLayout my="md">
                {defaultsEqual ? (
                  <DefaultAddressCard address={billing} />
                ) : (
                  <React.Fragment>
                    {billing && <DefaultAddressCard address={billing} />}
                    {shipping && <DefaultAddressCard address={shipping} />}
                  </React.Fragment>
                )}
              </AddressListLayout>
            )}
            {anyDefaults && anyRest && <Divider />}
            {anyRest && (
              <Box my="md">
                <H2>
                  <T id="addressBook.sectionTitle_other" />
                </H2>
                <AddressListLayout gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}>
                  {rest.map(x => (
                    <AddressCardLayout key={x.id}>
                      <AddressDetails {...x} />
                      <AddressActions addressId={x.id} />
                    </AddressCardLayout>
                  ))}
                </AddressListLayout>
              </Box>
            )}
            <FlexLayout flexDirection="column" alignItems="center" p="sm">
              <Button as={RouterLink} to="/compte/address-book/add" flex={1}>
                <T id="addressBook.addNewButton" />
              </Button>
            </FlexLayout>
          </React.Fragment>
        );
      }}
    </AddressListQuery>
  </Box>
);

export default AddressBook;


export const AddressCardUI = ({ address, isDefault }) => {
  const [addModal, setAddModal] = useState(Boolean);
  return (

    <RemoveAddressMutation>
      {(removeAddress, {loading}) => (


        <I18n>
          {t => (
            <>
              <AddressCard
                address={address}
                defaultAddress={isDefault}
                onRemove={() => removeAddress({variables: {id : address.id}})}
                openModal={() => setAddModal(true)}
              />

              {addModal &&
                <AddAddress closeModal={() => setAddModal(false)}/>
              }
            </>
          )}
        </I18n>
      )}
    </RemoveAddressMutation>
  );
}

export const AddressActions = ({ addressId }) => (
  <FlexLayout flexDirection="row" mt="xs">
    <EditAddressLink id={addressId} />
    <Divider variant="horizontal" mx="xs" />
    <RemoveAddressLink id={addressId} />
  </FlexLayout>
);

export const AddressDetails = ({ address }) => {
    const { company, street, postcode, city, countryId } = address;

    return (
        <div>
            {company && <span>{`${company}, `}</span>}
            {street && street.map(x => <span key={x}>{x}</span>)}
            <Text>{`${postcode} ${city}, ${countryId}`}</Text>
        </div>
    );
};


export const EditAddressLink = ({ id }) => (
  <Link as={RouterLink} to={`/compte/carnet-adresse/modifier/${id}`}>
    Modifier
  </Link>
);

const RemoveAddressLink = ({ id }) => (
  <RemoveAddressMutation>
    {(removeAddress, { loading }) => (
      <I18n>
        {t => (
          <React.Fragment>
            <Link
              onClick={() => {
                if (window.confirm(t('addressBook.removeConfirmationMessage'))) {
                  removeAddress({ variables: { id } });
                }
              }}
            >
              Supprimer
            </Link>
            {loading && <Icon ml="xs" src="loader" size="md" />}
          </React.Fragment>
        )}
      </I18n>
    )}
  </RemoveAddressMutation>
);
