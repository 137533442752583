import React from 'react';
import {CustomerQuery} from '@deity/falcon-ecommerce-uikit';
import {Dashboard as DashboardLayout} from "benlux-ecommerce-ui";

const Dashboard = () => (
  <CustomerQuery>
    {({customer}) => (
      <DashboardLayout customer={customer}/>
    )}
  </CustomerQuery>
);

export default Dashboard;
