import React, {useState} from 'react';
import {Formik} from 'formik';
import {Box} from '@deity/falcon-ui';
import {
    Form,
    FormErrorSummary,
    CustomerQuery,
    EditCustomerMutation,
    AddressListQuery,
  AddressListLayout,
  AddressCardLayout,
  AddressDetails
} from '@deity/falcon-ecommerce-uikit';
import {
  AddressActions,
  AddressCardUI,
  DefaultAddressCard
} from "./AddressBook/AddressBook";
import AddAddress from "./AddressBook/AddAddress";
import {FormField} from "../../account/SignIn/FormField";
import {AddAddress as AddButton} from "benlux-ecommerce-ui";


const PersonalInformation = () => {
    const [addModal, setAddModal] = useState(Boolean);

    return (
    <div style={{width: '100%'}}>
        <h1>Mes informations personnelles</h1>
        <p>BENLUX, enchantés. Et vous, qui êtes-vous ?</p>
        <AddressListQuery>
            {({addresses: {items}}) => {
                const billing = items.find(x => x.defaultBilling);
                const shipping = items.find(x => x.defaultShipping);
                const rest = items.filter(x => !x.defaultBilling && !x.defaultShipping) || [];
                const anyDefaults = billing || shipping;
                const defaultsEqual = (billing && billing.id) === (shipping && shipping.id);
                const anyRest = rest.length > 0;
                return (
                    <CustomerQuery>
                        {({customer}) => (
                            <EditCustomerMutation>
                                {(editCustomer, {loading, error}) => (
                                    <Formik
                                        initialValues={{
                                            firstname: customer.firstname,
                                            lastname: customer.lastname,
                                            email: customer.email
                                        }}
                                        onSubmit={values =>
                                            editCustomer({
                                                variables: {
                                                    input: {
                                                        ...values,
                                                        websiteId: customer.websiteId
                                                    }
                                                }
                                            })
                                        }

                                    >
                                        {() => (
                                            <>
                                                <Form>
                                                    <FormField name="firstname" type="text" required
                                                               placeholder={"Prénom"}/>
                                                    <FormField
                                                        name="lastname"
                                                        type="text"
                                                        required
                                                        placeholder={"Nom"}
                                                    />
                                                    <FormField name="email" type="email" required autoComplete="email"
                                                               placeholder={"Adresse email"}/>
                                                    <div style={{display: 'block', alignItems: "center"}}>
                                                      <h1>
                                                        Vos adresses
                                                      </h1>
                                                      {anyDefaults && (
                                                        <div style={{width: '100%', marginBottom: "10px"}}>
                                                          {defaultsEqual ? (
                                                              <AddressCardUI address={billing} isDefault={true}/>
                                                          ) : (
                                                              <React.Fragment>
                                                                  {billing && <AddressCardUI address={billing} isDefault={true}/>}
                                                                  {shipping &&
                                                                  <AddressCardUI address={shipping} isDefault={true}/>}
                                                              </React.Fragment>
                                                          )}
                                                        </div>
                                                      )}
                                                      {anyRest && (
                                                        <Box my="md">
                                                          <AddressListLayout
                                                            gridTemplateColumns={{md: 'repeat(2, 1fr)'}}>
                                                            {rest.map(x => (
                                                              <AddressCardUI address={x} isDefault={false}/>
                                                            ))}
                                                          </AddressListLayout>
                                                        </Box>
                                                        )}
                                                        <AddButton openModal={() => setAddModal(true)}/>
                                                        {addModal &&
                                                          <AddAddress closeModal={() => setAddModal(false)}/>
                                                        }
                                                    </div>
                                                    <div className={"rightObject"}>
                                                        <input type={'submit'} className={"buttonConnect"}
                                                               value={'Enregistrer'}/>
                                                    </div>
                                                    <FormErrorSummary errors={error && [error.message]}/>
                                                </Form>
                                            </>
                                        )}
                                    </Formik>
                                )}
                            </EditCustomerMutation>
                        )}
                    </CustomerQuery>
                );
            }}
        </AddressListQuery>
    </div>
);
}

export default PersonalInformation;
