import React, {useState} from 'react';
import { Formik } from 'formik';
import {
  Form,
  FormErrorSummary,
  ChangePasswordMutation,
} from '@deity/falcon-ecommerce-uikit';
import {FormField, FormFieldPassword} from "../../account/SignIn/FormField";
import {ButtonTypes, Button} from "benlux-ecommerce-ui";

const ChangePassword = ({ history }) => {
const [passwordCheck, setPasswordCheck] = useState();

  return (
  <div style={{width: '100%'}}>
    <h1 style={{color: '#000000', fontSize: 20}}>Changez votre mot de passe</h1>
    <h2 style={{color: '#696969', fontSize: 14}}>Sécurisez votre compte</h2>
      <ChangePasswordMutation>
        {(changePassword, { loading, error }) => (
          <Formik
            initialValues={{ currentPassword: '', password: '' }}
            onSubmit={values => changePassword({ variables: { input: values } }).then(() => history.push('/compte'))}
          >
            {() => (
              <Form>
                {/*<FormField*/}
                {/*  name="currentPassword"*/}
                {/*  type="password"*/}
                {/*  // pass empty array, so default password strength validator does not get triggered*/}
                {/*  validate={[]}*/}
                {/*  required*/}
                {/*  placeholder={'Ancien mot de passe'}*/}
                {/*>*/}
                {/*  {({ field }) => <PasswordRevealInput {...field} />}*/}
                {/*</FormField>*/}
                {/*<FormField*/}
                {/*  name="password"*/}
                {/*  type="password"*/}
                {/*  // pass empty array, so default password strength validator does not get triggered*/}
                {/*  validate={[]}*/}
                {/*  required*/}
                {/*  autoComplete="new-password"*/}
                {/*  placeholder={'Choisir un nouveau mot de passe'}*/}

                {/*>*/}
                {/*  {({ field }) => <PasswordRevealInput {...field} />}*/}
                {/*</FormField>*/}
                <FormFieldPassword
                  name="currentPassword"
                  required
                  type="password"
                  errorMessage={"Ancien mot de passe requis"}
                  placeholder={"Ancien mot de passe"}
                  passwordValidator={false}
                />
                <FormFieldPassword
                  name="password"
                  required
                  type="password"
                  errorMessage={"Nouveau mot de passe requis"}
                  autoComplete="new-password"
                  placeholder={'Choisir un nouveau mot de passe'}
                  validPassword={(v) => setPasswordCheck(v)}
                  passwordValidator={true}
                />
                <div className={"rightObject"}>
                  <Button type={ButtonTypes.Secondary} as={'submit'}
                          text={'Enregistrer'} loading={loading}/>
                </div>
                <FormErrorSummary errors={error && [error.message]} />
              </Form>
            )}
          </Formik>
        )}
      </ChangePasswordMutation>
  </div>
  )
}

export default ChangePassword;
