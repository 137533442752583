import React from 'react';
import { NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import { T } from '@deity/falcon-i18n';
import { H1, FlexLayout, GridLayout } from '@deity/falcon-ui';
import {
  Form,
  CheckboxFormField,
  FormErrorSummary,
  TwoColumnsLayout,
  TwoColumnsLayoutArea,
  AddAddressMutation,
  CountriesQuery,
  CountrySelector
} from '@deity/falcon-ecommerce-uikit';
import '../Information.css'
import {FormField} from "../../../account/SignIn/FormField";
import {Button, ButtonTypes} from "benlux-ecommerce-ui";

const AddAddress = ({ history, closeModal }) => (
    <div className={'overlay'}>
    <div className={'modalAddress'}>
  <GridLayout mb="md" gridGap="md">
    <H1>
      Ajouter une adresse
    </H1>
    <span>Où souhaitez-vous envoyer vos colis ?</span>
    <AddAddressMutation>
      {(addAddress, { loading, error }) => (
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            street1: '',
            street2: '',
            postcode: '',
            city: '',
            countryId: 'FR',
            company: '',
            telephone: '',
            defaultBilling: false,
            defaultShipping: false
          }}
          onSubmit={({ street1, street2, ...values }) =>
            addAddress({
              variables: {
                input: {
                  ...values,
                  street: [street1, street2]
                }
              }
            }).then(() => history.push('/compte/address-book'))
          }
        >
          {() => (
            <Form id="add-address" i18nId="addAddress">
              <TwoColumnsLayout>
                <GridLayout gridArea={TwoColumnsLayoutArea.left}>
                  <FormField name="company" placeholder={'Entreprise'} type={'text'}/>
                  <FormField name="firstname" required placeholder={'Prénom'} type={'text'}/>
                  <FormField name="lastname" required placeholder={'Nom de famille'} type={'text'}/>
                  <FormField name="telephone" placeholder={'Téléphone'}/>
                </GridLayout>
                <GridLayout gridArea={TwoColumnsLayoutArea.right}>
                  <FormField name="street1" required placeholder={'Adresse'} type={'text'}/>
                  <FormField name="postcode" required placeholder={'Code postal'} type={'text'}/>
                  <FormField name="city" required placeholder={'Ville'} type={'text'}/>
                  <FormField name="countryId" required placeholder={'Pays'}>
                    {({ form, field }) => (
                      <CountriesQuery passLoading>
                        {({ countries = { items: [] } }) => (
                          <CountrySelector
                            {...field}
                            onChange={x => form.setFieldValue(field.name, x)}
                            items={countries.items}
                          />
                        )}
                      </CountriesQuery>
                    )}
                  </FormField>
                </GridLayout>
              </TwoColumnsLayout>
              <CheckboxFormField name="defaultBilling" label="Adresse de facturation par défaut"/>
              <CheckboxFormField name="defaultShipping" label="Adresse de livraison par défaut"/>
              <FlexLayout justifyContent="flex-end" alignItems="center" mt="md">
                <div onClick={closeModal} style={{marginRight: 20, cursor: 'pointer'}}>Fermer</div>
                <div className={"rightObject"}>
                  <Button type={ButtonTypes.Secondary} as={'submit'}
                          text={'Enregistrer'} loading={loading}/>
                </div>
              </FlexLayout>
              <FormErrorSummary errors={error && [error.message]} />
            </Form>
          )}
        </Formik>
      )}
    </AddAddressMutation>
  </GridLayout>
    </div>
    </div>
);

export default AddAddress;
