import React from 'react';
import { NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import { T } from '@deity/falcon-i18n';
import { H1, Text, FlexLayout, GridLayout } from '@deity/falcon-ui';
import {
  Form,
  CheckboxFormField,
  FormErrorSummary,
  AddressQuery,
  getAddressType,
  GET_ADDRESS,
  EditAddressMutation,
  TwoColumnsLayout,
  TwoColumnsLayoutArea,
  CountriesQuery,
  CountrySelector
} from '@deity/falcon-ecommerce-uikit';
import {FormField} from "../../../account/SignIn/FormField";
import {ButtonTypes, Button} from "benlux-ecommerce-ui";

const EditAddress = ({ match, history }) => {
  const id = parseInt(match.params.id, 10);

  return (
    <AddressQuery variables={{ id }}>
      {({ address }) => (
        <div>
          <h1>Modifier mon adresse</h1>
          {(address.defaultBilling || address.defaultShipping) && (
            <Text>
              d
              <T id="editAddress.defaultAddressLabel" context={getAddressType(address)} />
            </Text>
          )}
          <EditAddressMutation refetchQueries={['Addresses', { query: GET_ADDRESS, variables: { id } }]}>
            {(editAddress, { loading, error }) => (
              <Formik
                initialValues={{
                  firstname: address.firstname,
                  lastname: address.lastname,
                  street1: address.street[0],
                  street2: address.street.length > 1 ? address.street[1] : '',
                  postcode: address.postcode,
                  city: address.city,
                  countryId: address.countryId,
                  company: address.company || undefined,
                  telephone: address.telephone,
                  defaultBilling: address.defaultBilling,
                  defaultShipping: address.defaultShipping
                }}
                onSubmit={({ street1, street2, ...values }) =>
                  editAddress({
                    variables: {
                      input: {
                        ...values,
                        id,
                        street: [street1, street2]
                      }
                    }
                  }).then(() => history.push('/compte/informations-personnelles'))
                }
              >
                {() => (
                  <Form id={id} i18nId="editAddress">
                    <CheckboxFormField name="defaultBilling" label="Adresse de facturation par défaut" />
                    <CheckboxFormField name="defaultShipping" label="Adresse de livraison par défaut"/>
                    <TwoColumnsLayout>
                      <GridLayout gridArea={TwoColumnsLayoutArea.left}>
                        <FormField name="company" placeholder={"Entreprise"}/>
                        <FormField name="firstname" required placeholder={"Prénom"}/>
                        <FormField name="lastname" required placeholder={"Nom"}/>
                        <FormField name="telephone" placeholder={"Téléphone"}/>
                      </GridLayout>
                      <GridLayout gridArea={TwoColumnsLayoutArea.right}>
                        <FormField name="street1" required placeholder={"Adresse"}/>
                        <FormField name="street2" placeholder={"Complément d'adresse"}/>
                        <FormField name="postcode" required placeholder={"Code Postal"}/>
                        <FormField name="city" required placeholder={"Ville"}/>
                        <FormField name="countryId" required placeholder={"Pays"}>
                          {({ form, field }) => (
                            <CountriesQuery passLoading>
                              {({ countries = { items: [] } }) => (
                                <CountrySelector
                                  {...field}
                                  onChange={x => form.setFieldValue(field.name, x)}
                                  items={countries.items}
                                />
                              )}
                            </CountriesQuery>
                          )}
                        </FormField>
                      </GridLayout>
                    </TwoColumnsLayout>
                    <FlexLayout justifyContent="flex-end" alignItems="center" mt="md">
                      <FormErrorSummary errors={error && [error.message]} />
                      <Button type={ButtonTypes.Secondary} as={'submit'}
                              text={'Modifier'} loading={loading}/>
                      {/*<Button type="submit" variant={loading ? 'loader' : undefined}>*/}
                      {/*  <T id="editAddress.submitButton" />*/}
                      {/*</Button>*/}
                    </FlexLayout>
                  </Form>
                )}
              </Formik>
            )}
          </EditAddressMutation>
        </div>
      )}
    </AddressQuery>
  );
};

export default EditAddress;
