import React from 'react';
import {OrderListQuery} from '@deity/falcon-ecommerce-uikit';
import {OrderCard} from "benlux-ecommerce-ui";
import './Orders.css';

const Orders = () => (
  <div style={{width: '100%'}}>
    <OrderListQuery>
      {({orders: {items}, orders}) => {
        return (
          items.length ? (
          <>
            <h1 className={'orderH1'}>Mes commandes</h1>
            {items.map((item, index) => (
                  <div key={index}>
                      <OrderCard item={item} />
                  </div>
              ))}
          </>
        ) : (
            <div>Vous n'avez pas encore de commandes</div>
        )
        )
      }}
    </OrderListQuery>
  </div>
);

export default Orders;
